
import { defineComponent, computed } from 'vue'
import { useStore } from '@/utils/composables'

import MyndshftTable from '@/components/table/table.vue'
import MyndshftTableCell from '@/components/table/cell.vue'

export default defineComponent({
	name: 'client-duplication-check',
	components: {
		[MyndshftTable.name]: MyndshftTable,
		[MyndshftTableCell.name]: MyndshftTableCell,
	},
	setup() {
		const store = useStore()

		const duplicates = computed(() => store.state.clientWizard.duplicates)

		const clientName = computed(
			() => store.state.clientWizard.client.client.name
		)

		const clientFirstLast = computed(() => {
			const client = store.state.clientWizard.client.client
			return `${client?.firstName} ${client?.lastName}`
		})

		const columns = [
			{
				field: '',
				label: 'Client Name',
			},
			{
				field: '',
				label: '',
			},
		]

		return {
			clientName,
			clientFirstLast,
			duplicates,
			columns,
		}
	},
})

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"container"},[_c('wizard-steps',{attrs:{"id":"client-wizard","steps":_vm.steps}}),_c('myndshft-panel',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function({ invalid }){return [_c('form',{ref:"form"},[(
						_vm.wizardStep === 0 &&
						!_vm.state.matches('wizard.steps.duplication.confirmation')
					)?_c('client-information',{key:"information"}):(_vm.wizardStep === 0)?_c('client-duplication-check',{key:"duplication"}):_vm._e(),_c('keep-alive',[(_vm.wizardStep === 1)?_c('client-wizard-transactions',{key:"transactions"}):_vm._e()],1),(_vm.wizardStep === 2)?_c('client-confirmation',{key:"confirmation"}):_vm._e(),_c('nav',[_c('button',{staticClass:"button ms-btn",class:{ 'is-loading': _vm.isTransitioning },style:({
							visibility:
								_vm.wizardStep === 0 &&
								!_vm.state.matches('wizard.steps.duplication.confirmation')
									? 'hidden'
									: 'visible',
						}),attrs:{"id":"back-button"},on:{"click":function($event){$event.preventDefault();return _vm.transition(_vm.PREV)}}},[_vm._v(" Back ")]),_c('button',{staticClass:"button ms-btn ms-btn-primary is-pulled-right",class:{
							'is-loading':
								_vm.isTransitioning ||
								_vm.state.matches('wizard.steps.creatingClient'),
						},attrs:{"disabled":!_vm.$canEditClientDetail() || invalid || !_vm.canSaveTransactions},on:{"click":function($event){$event.preventDefault();return _vm.transitionNext.apply(null, arguments)}}},[(
								_vm.state.matches('wizard.steps.confirmation') ||
								_vm.state.matches('wizard.steps.creatingClient') ||
								_vm.state.matches('wizard.steps.success')
							)?_c('span',{attrs:{"id":"create-button"}},[_vm._v("Create")]):_c('span',{attrs:{"id":"next-button"}},[_vm._v("Next")])])])],1)]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
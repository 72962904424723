
import { defineComponent } from 'vue'

import ClientWizard from '@/components/client/wizard/wizard.vue'

export default defineComponent({
	components: {
		[ClientWizard.name]: ClientWizard,
	},
	beforeRouteLeave(to, _, next) {
		if (to.name === 'client-id') {
			next()
		} else {
			next(
				confirm(
					'If you leave the wizard, your unsaved changes will be discarded.'
				) as any
			)
		}
	},
})

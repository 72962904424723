
import { defineComponent, computed, ref } from 'vue'
import { ActiveStatus } from '@myndshft/types-mc-api'
import { useStore } from '@/utils/composables'

import {
	IClientTransaction,
	ClientTransactionTypes,
} from '@server/api/models/fe/client'

import ClientTransactions from '@/components/client/client-transactions.vue'

export default defineComponent({
	name: 'client-wizard-transactions',
	components: {
		ClientTransactions,
	},
	setup() {
		const store = useStore()

		const initialTransactionState = (): IClientTransaction[] => [
			{
				name: ClientTransactionTypes.ELIGIBILITY,
				isActive: false,
			},
			{
				name: ClientTransactionTypes.BATCH_ELIGIBILITY,
				isActive: false,
			},
			{
				name: ClientTransactionTypes.PRIOR_AUTH_DETERMINATION,
				isActive: false,
			},
			{
				name: ClientTransactionTypes.PRIOR_AUTH_SUBMISSION,
				isActive: false,
			},
			{
				name: ClientTransactionTypes.PHARMACY_PRIOR_AUTH_SUBMISSION,
				isActive: false,
			},
			{
				name: ClientTransactionTypes.PATIENT_RESPONSIBILITY,
				isActive: false,
			},
			{
				name: ClientTransactionTypes.INSURANCE_DISCOVERY,
				isActive: false,
			},
		]
		const transactions = ref<IClientTransaction[]>(initialTransactionState())

		const clientName = computed(() => {
			const client = store.state.clientWizard.client.client
			return client?.name || `${client.firstName} ${client.lastName}`
		})

		const activateClient = computed<string>({
			get: () => {
				return store.state.clientWizard.client.client.status
			},
			set: value => {
				store.commit('clientWizard/client/setIsActive', value)
			},
		})

		const isClientActive = computed<boolean>(
			() => activateClient.value === ActiveStatus.ACTIVE
		)

		const enableAutoActivation = computed<boolean>({
			get: () => {
				return store.state.clientWizard.client.client.willAutoActivate
			},
			set: value => {
				store.commit('clientWizard/client/setWillAutoActivate', value)
			},
		})

		const updateClientTransactions = (
			transactionList: IClientTransaction[]
		) => {
			transactions.value = transactionList
			store.commit('clientWizard/client/setTransactions', transactions.value)
		}

		const onActivateToggle = (status: string) => {
			if (status === 'INACTIVE') {
				updateClientTransactions(initialTransactionState())
			}
		}

		return {
			clientName,
			activateClient,
			isClientActive,
			enableAutoActivation,
			transactions,
			onActivateToggle,
			updateClientTransactions,
		}
	},
})

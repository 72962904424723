
import {
	defineComponent,
	ref,
	computed,
	watchEffect,
	onBeforeMount,
	onBeforeUnmount,
} from 'vue'
import { Alert, toast } from '@/dependencies/sweet-alert'
import { useStore } from '@/utils/composables'

import ClientInformation from '@/components/client/wizard/steps/clientInformation.vue'
import DuplicationCheck from '@/components/client/wizard/steps/duplicationCheck.vue'
import ClientWizardTransactions from '@/components/client/wizard/steps/clientWizardTransactions.vue'
import ClientConfirmation from '@/components/client/wizard/steps/confirmation.vue'

import WizardSteps from '@/components/shared/wizard/steps.vue'
import { useWizard } from '@/components/shared/wizard/steps'
import { WizardStep } from '@/components/shared/wizard/types'
import { useVuexMachine } from '@/store/utils/machine'

import wizardMachine from '@/store/client-wizard/machine'
import { MachineEvent, NULL_EVENT } from '@/store/utils/machine'

const wizardSteps: WizardStep[] = [
	{
		label: 'Client Information',
		icon: 'user',
	},
	{
		label: 'Transactions',
		icon: 'cog',
	},
	{
		label: 'Confirmation',
		icon: 'check',
	},
]

export default defineComponent({
	name: 'client-wizard',
	components: {
		[WizardSteps.name]: WizardSteps,
		[ClientInformation.name]: ClientInformation,
		[DuplicationCheck.name]: DuplicationCheck,
		[ClientWizardTransactions.name]: ClientWizardTransactions,
		[ClientConfirmation.name]: ClientConfirmation,
	},
	setup() {
		const store = useStore()
		const { steps, currentStep } = useWizard(wizardSteps)
		const { state, transition } = useVuexMachine(
			store,
			wizardMachine,
			'clientWizard'
		)

		const wizardStep = computed(
			() => store.getters['clientWizard/wizardStep'] as number
		)

		const canSaveTransactions = computed(() => {
			const canSave = store.getters['clientWizard/client/canSaveTransactions']
			if (!canSave) {
				toast({
					type: Alert.INFO,
					title:
						'Using Prior Auth Submission also requires Prior Auth Requirements.',
					timer: 5000,
				})
			}
			return canSave
		})

		onBeforeMount(() => {
			transition(NULL_EVENT)
		})

		onBeforeUnmount(() => {
			store.dispatch('clientWizard/reset')
		})

		watchEffect(() => {
			currentStep.value = wizardStep.value
		})

		// State Machine Events
		const PREV: MachineEvent = { type: 'PREV' }
		const NEXT: MachineEvent = { type: 'NEXT' }

		const form = ref(null)
		const validateForm = () => (form.value as any).reportValidity()
		const isTransitioning = ref(false)

		const transitionNext = () => {
			isTransitioning.value = true
			if (validateForm()) {
				transition(NEXT)
			}
			isTransitioning.value = false
		}

		return {
			steps,
			state,
			wizardStep,
			transition,
			transitionNext,
			canSaveTransactions,

			PREV,
			NEXT,

			form,
			validateForm,

			isTransitioning,
		}
	},
})

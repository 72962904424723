
import { defineComponent, computed, ref, PropType } from 'vue'

import { useFormatting } from '@/dependencies/formatting'
import { IInboundFax } from '../../../../../server/api/models/fe/client'

export default defineComponent({
	name: 'myndshft-inbound-fax',
	props: {
		value: { type: Object as PropType<IInboundFax>, required: true },
	},
	setup(props, context) {
		const showSecret = ref(false)

		const update = (key: string, value: any) => {
			context.emit('input', {
				...props.value,
				[key]: value,
			})
		}

		const isRequired = computed(() => {
			const { username, secret } = props.value
			const faxNumber = props.value.number
			const faxDetails = {
				username,
				faxNumber,
				secret,
			}
			return !!Object.values(faxDetails).some(Boolean)
		})

		const onFaxNumber = (value: string) => {
			const { toPhoneNumber } = useFormatting()
			props.value.number = toPhoneNumber(value)
		}

		return {
			isRequired,
			showSecret,
			update,
			onFaxNumber,
		}
	},
})


import { defineComponent, computed, ref, onMounted } from 'vue'
import { useStore } from '@/utils/composables'

import { ActiveStatus } from '@myndshft/types-mc-api'
import {
	IClientTransaction,
	ClientTransactionTypes,
} from '@server/api/models/fe/client'
import { IAddressFE, IServiceType } from '@server/api/models/fe/shared'
import { fetchTenantList } from '@/service/tenant.http'

export default defineComponent({
	name: 'client-confirmation',
	setup() {
		const store = useStore()

		const client = computed(() => store.state.clientWizard.client.client)
		const serviceTypes = computed(() => store.state.serviceTypes.types)
		const inputTransactions = computed(
			() => store.state.clientWizard.client.transactions
		)
		const inboundFax = computed(
			() => store.state.clientWizard.client.inboundFax
		)

		const formatAddress = (address: IAddressFE) => {
			const addressLines = [address.lineOne, address.lineTwo]
				.filter(Boolean)
				.join('\n')
			const cityStateZip = `${address.city}, ${address.state ?? '??'} ${
				address.zip
			}`
			return [addressLines, cityStateZip].join('\n')
		}

		const clientInformation = computed(() => {
			return {
				'Client Name': client.value.name,
				'Provider First Name': client.value.firstName,
				'Provider Last Name': client.value.lastName,
				'Service Type Category': serviceTypes.value.find(
					(t: IServiceType) => t.ID === client.value.serviceTypeID
				)?.name,
				'National Provider Identifier (NPI)': client.value.npi,
				'HubSpot Account ID': client.value.crmID,
				'Federal Tax ID': client.value.federalTaxId,
				'Place Of Service': client.value.placeOfService?.code
					? `${client.value.placeOfService.code} - ${client.value.placeOfService.description}`
					: null,
				Address: client.value.address.lineOne
					? formatAddress(client.value.address)
					: null,
				'InterFAX Username': inboundFax.value.username,
				'InterFAX Number': inboundFax.value.number,
				'InterFAX Secret': inboundFax.value.secret,
			}
		})

		const findTransaction = (name: ClientTransactionTypes) => {
			return (
				inputTransactions.value.find((t: IClientTransaction) => t.name === name)
					?.isActive || false
			)
		}

		const transactions = computed(() => {
			return {
				'Benefits Eligibility': findTransaction(
					ClientTransactionTypes.ELIGIBILITY
				),
				'Batch Eligibility': findTransaction(
					ClientTransactionTypes.BATCH_ELIGIBILITY
				),
				'Prior Auth Requirements': findTransaction(
					ClientTransactionTypes.PRIOR_AUTH_DETERMINATION
				),
				'Prior Auth Submission': findTransaction(
					ClientTransactionTypes.PRIOR_AUTH_SUBMISSION
				),
				'Pharmacy Prior Auth Submission': findTransaction(
					ClientTransactionTypes.PHARMACY_PRIOR_AUTH_SUBMISSION
				),
				'Patient Responsibility': findTransaction(
					ClientTransactionTypes.PATIENT_RESPONSIBILITY
				),
				'Insurance Discovery': findTransaction(
					ClientTransactionTypes.INSURANCE_DISCOVERY
				),
			}
		})

		const activation = computed(() => {
			return {
				'Activate on Creation?': client.value.status === ActiveStatus.ACTIVE,
				'Enable Auto-Activation':
					client.value.willAutoActivate === ActiveStatus.ACTIVE,
			}
		})

		const tenantName = ref('')
		onMounted(async () => {
			fetchTenantList().then(res => {
				tenantName.value = res.tenants.filter(
					tenant => tenant.id === client.value.tenantId
				)[0].name
			})
		})

		return {
			client,
			clientInformation,
			transactions,
			activation,
			tenantName,
		}
	},
})

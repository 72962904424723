
import { defineComponent, ref, watch } from 'vue'
import { useStore } from '@/utils/composables'

import MyndshftAddress from '@/components/client/wizard/steps/address.vue'
import MyndshftInboundFax from '@/components/client/wizard/steps/interfax.vue'
import MyndshftClientProfile from '@/components/client/wizard/steps/profile.vue'
import { IClientInformation } from '@server/api/models/fe/client'

export default defineComponent({
	name: 'client-information',
	components: {
		[MyndshftClientProfile.name]: MyndshftClientProfile,
		[MyndshftAddress.name]: MyndshftAddress,
		[MyndshftInboundFax.name]: MyndshftInboundFax,
	},
	setup() {
		const store = useStore()
		const state = store.state
		const client = state.clientWizard.client.client

		const clientInformation = ref<IClientInformation>({
			client: {
				name: client.name,
				firstName: client.firstName,
				lastName: client.lastName,
				serviceTypeID: client.serviceTypeID,
				npi: client.npi !== 0 ? client.npi : '',
				crmID: client.crmID,
				tenantId: client.tenantId,
				federalTaxId: client.federalTaxId,
				placeOfService: client.placeOfService,
			},
			address: state.clientWizard.client.client.address,
			inboundFax: state.clientWizard.client.inboundFax,
		})

		watch(
			clientInformation,
			clientInfo => {
				store.commit('clientWizard/client/setClientInformation', clientInfo)
			},
			{ deep: true }
		)

		return {
			clientInformation,
		}
	},
})

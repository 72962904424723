
import { defineComponent, computed, ref, onMounted, PropType } from 'vue'
import { Alert, toast } from '@/dependencies/sweet-alert'
import { useStore } from '@/utils/composables'

import MyndshftInput from '@/components/form/input.vue'
import { IPlaceOfService, IServiceType } from '@server/api/models/fe/shared'
import { ITenantDetails } from '@server/api/models/fe/tenant'
import { fetchTenantList } from '@/service/tenant.http'
import { fetchPlacesOfService } from '@/service/shared.http'

export interface Value {
	name: string
	firstName: string
	lastName: string
	serviceTypeID: number | null
	npi: string
	crmID: string
	tenantId: string
	federalTaxId: string | null
	placeOfService: string | null
}

export default defineComponent({
	name: 'myndshft-client-profile',
	components: {
		[MyndshftInput.name]: MyndshftInput,
	},
	props: {
		value: Object as PropType<Value>,
	},
	setup(props, context) {
		const store = useStore()

		const posOptions = ref<IPlaceOfService[]>([])
		const client = ref('')
		const npi = ref('')

		const serviceTypes = computed(() => store.state.serviceTypes.types)

		if (serviceTypes.value.length === 0) {
			store.dispatch('serviceTypes/fetchServiceTypes').catch(() => {
				toast({
					type: Alert.ERROR,
					title: 'Failed to get client service types',
				})
			})
		}

		const serviceType = computed(() => {
			if (serviceTypes.value) {
				return serviceTypes.value.find(
					(type: IServiceType) =>
						type.ID === store.state.clientWizard.client.serviceTypeID
				)
			}
			return ''
		})

		const tenants = ref<ITenantDetails[]>([])

		const update = (key: string, value: any) => {
			context.emit('input', {
				...props.value,
				[key]: value,
			})
		}

		const isRequiredName = computed(() => ({
			clientName: !props.value?.firstName && !props.value?.lastName,
			firstName: !props.value?.name || !!props.value?.lastName,
			lastName: !props.value?.name || !!props.value?.firstName,
		}))

		const formatFederalTaxId = () => {
			const taxId = props.value?.federalTaxId
			if (taxId) {
				update('federalTaxId', taxId.replace(/-/g, ''))
			}
		}

		onMounted(() => {
			fetchTenantList().then(res => (tenants.value = res.tenants))
			fetchPlacesOfService().then(res => (posOptions.value = res))
		})

		return {
			client,
			serviceType,
			serviceTypes,
			npi,
			tenants,
			isRequiredName,
			posOptions,
			update,
			formatFederalTaxId,
		}
	},
})
